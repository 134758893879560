import "../css/common.css";
import "../css/style.css";

import {utils} from"./utils.js"
import {Swiper} from "swiper"




import("./jq.js").then(($)=>{
    
    var e = "http://newgame.holyfinger.com:8083/api/";
    window.location.href.indexOf("https") >= 0 && (e = e.replace("http://newgame.holyfinger.com:8083", "https://newgame.holyfinger.com"))
    var currPage = utils.getLocalData("record_page");
    var gameList = utils.getLocalData("minigame_data")
    var loading = true
    var valParams = ""
    var currIdx= 0;
    var isExpanded = false;
    if(!currPage)
    {
        currPage = 1
    }
    $(document).ready(function(){
        var type_arr = ["Best Games","Bubble Shooter","Board Games","Match3","Racing","Quiz","Combat","Girls","Action","Skill","Sports","Time Management","Multiplayer","Shoot","Music"]
        $(".game-type").find("a").each(function(i,dd){
            $(this).attr("href","./type.html?type="+type_arr[i])
        })
        
        $(".ipt-search").change(function(e){
            valParams = e.target.value;
        })
        $(".btn-search").click(function(){
            window.location.href="./search.html?searchTxt="+valParams
        })
        utils.t({
            url: e + "ogame",
            type: "post",
            data: JSON.stringify({
                channel: "thumb-game",
                page:1,
                type:"lb"
            }),
            timeout: 5e3,
            error: function() {},
            success: function(e) {
                try {
                    if (e) {
                        var n = JSON.parse(e),
                            t = n.data,
                            o = $(".swiper-wrapper"), s = "",ss=""; 
                            t.map(e => {
                                s += '<div class="swiper-slide"><a href="info.html?id=' + e.id + '"><img alt="" src="' + (e.gameBigImage||'./images/default1.png') + '" /></a></div>'
                            }), o.html(s);
                            $(".slide-num").text((currIdx+1)+"/"+t.length)
                            var swiper = new Swiper(".mySwiper", {
                                autoplay:{
                                    delay:`1000`
                                },
                                loop: true,
                                on: {
                                    slideChange: function(){
                                        currIdx =this.realIndex
                                        $(".slide-num").text((currIdx+1)+"/"+t.length)
                                    },
                                    observerUpdate: function(){
                                    }, 
                                },
                            }
                            ); 
                    }
                } catch {}
            }
        })
        utils.t({
            url: e + "ogame",
            type: "post",
            data: JSON.stringify({
                "channel":"thumb-game",
                type: "POPULAR GAMES",
                page:1
            }),
            timeout: 5e3,
            error: function() {},
            success: function(d) {
                var  cardNode = $(".card").eq(1)
                try {
                    if (d) {
                        var n = JSON.parse(d),
                            t = n.data,
                            l= t.splice(0,1),
                            gameList = t.splice(0,4),
                            gameList2 = t.splice(0,4),
                            ss="",
                            gameTxt="";
                            cardNode.find(".title>a").attr("href",'/more2.html?type=POPULAR GAMES')
                            cardNode.find(".lt>a").attr("href",'/info.html?id='+l[0].id)
                            cardNode.find(".img1").attr("src",l[0].gamelogo)
                            cardNode.find(".txt").text(l[0].gamename)
                            gameList.map(e=>{
                                gameTxt+='<a href="info.html?id=' + e.id + '"><img alt="" src="' + (e.gamelogo||'./images/default.png') + '" /><p><span class="txt">'+e.gamename+'</span></p></a>'
                            })
                            cardNode.find(".rt").html(gameTxt)
                            gameList2.map(e => {
                                ss += '<div class="item"><a href="info.html?id=' + e.id + '"><img alt="" src="' + (e.gamelogo||'./images/default.png') + '" /></a><p><span class="txt">'+e.gamename+'</span></p></div>'
                            })
                            cardNode.find(".box-game").html(ss);
                    }
                } catch {}
            }
        })
        utils.t({
            url: e + "ogame",
            type: "post",
            data: JSON.stringify({
                "channel":"thumb-game",
                "type":"TODAY TOP"
            }),
            timeout: 5e3,
            error: function() {},
            success: function(e) {
                var  cardNode = $(".top1").eq(0)

                try {
                    if (e) {
                        var s = JSON.parse(e),
                            t = s.data,
                            a = "",
                            c = t.splice(0, 5)
                            
                            c.map((e,idx) => {
                                var starNum = parseInt(e.score)
                                var starTxt = ""
                                for(var i=0;i<starNum;i++)
                                {
                                    starTxt+= "<img src='"+require("../images/icon_star.png")+"' alt='' class='icon-star' />"
                                }
                                a += '<li class="items"><a href="info.html?id='+e.id+'"><div class="items-idx">'+(idx+1)+'</div><img class="imgs" src="'+(e.gamelogo||'./images/default.png')+'" alt=""/><div class="g-info"><p>'+e.gamename+'</p><div class="star">'+starTxt+'</div></div></a></li>'
                            })
                            cardNode.find(".top-con").html(a)
                            // var imgs = $('.items');
                            // utils.lazyload(imgs);
                    }
                } catch {}
            }
        })
        utils.t({
            url: e + "ogame",
            type: "post",
            data: JSON.stringify({
                "channel":"thumb-game",
                "type":"NEW TOP"
            }),
            timeout: 5e3,
            error: function() {},
            success: function(e) {
                var  cardNode = $(".top1").eq(1)
 
                try {
                    if (e) {
                        var s = JSON.parse(e),
                            t = s.data,
                            a = "",
                            c = t.splice(0, 5)
                            
                            c.map((e,idx) => {
                                var starNum = parseInt(e.score)
                                var starTxt = ""
                                for(var i=0;i<starNum;i++)
                                {
                                    starTxt+= "<img src='"+require("../images/icon_star.png")+"' alt='' class='icon-star' />"
                                }
                                a += '<li class="items"><a href="info.html?id='+e.id+'"><div class="items-idx">'+(idx+1)+'</div><img class="imgs" src="'+(e.gamelogo||'./images/default.png')+'" alt=""/><div class="g-info"><p>'+e.gamename+'</p><div class="star">'+starTxt+'</div></div></a></li>'
                            })
                            cardNode.find(".top-con").html(a)
                            // var imgs = $('.items');
                            // utils.lazyload(imgs);
                    }
                } catch {}
            }
        })
        utils.t({
            url: e + "ogame",
            type: "post",
            data: JSON.stringify({
                "channel":"thumb-game",
                "type":"BEST TOP"
            }),
            timeout: 5e3,
            error: function() {},
            success: function(e) {
                var  cardNode = $(".top1").eq(2)

                try {
                    if (e) {
                        var s = JSON.parse(e),
                            t = s.data,
                            a = "",
                            c = t.splice(0, 5)
                            
                            c.map((e,idx) => {
                                var starNum = parseInt(e.score)
                                var starTxt = ""
                                for(var i=0;i<starNum;i++)
                                {
                                    starTxt+= "<img src='"+require("../images/icon_star.png")+"' alt='' class='icon-star' />"
                                }
                                a += '<li class="items"><a href="info.html?id='+e.id+'"><div class="items-idx">'+(idx+1)+'</div><img class="imgs" src="'+(e.gamelogo||'./images/default.png')+'" alt=""/><div class="g-info"><p>'+e.gamename+'</p><div class="star">'+starTxt+'</div></div></a></li>'
                            })
                            cardNode.find(".top-con").html(a)
                            // var imgs = $('.items');
                            // utils.lazyload(imgs);
                    }
                } catch {}
            }
        })
        utils.t({
            url: e + "ogame",
            type: "post",
            data: JSON.stringify({
                "channel":"thumb-game",
                "type":"FEATURED TOP"
            }),
            timeout: 5e3,
            error: function() {},
            success: function(e) {
                var  cardNode = $(".top1").eq(3)

                try {
                    if (e) {
                        var s = JSON.parse(e),
                            t = s.data,
                            a = "",
                            c = t.splice(0, 5)
                            
                            c.map((e,idx) => {
                                var starNum = parseInt(e.score)
                                var starTxt = ""
                                for(var i=0;i<starNum;i++)
                                {
                                    starTxt+= "<img src='"+require("../images/icon_star.png")+"' alt='' class='icon-star' />"
                                }
                                a += '<li class="items"><a href="info.html?id='+e.id+'"><div class="items-idx">'+(idx+1)+'</div><img class="imgs" src="'+(e.gamelogo||'./images/default.png')+'" alt=""/><div class="g-info"><p>'+e.gamename+'</p><div class="star">'+starTxt+'</div></div></a></li>'
                            })
                            cardNode.find(".top-con").html(a)
                            // var imgs = $('.items');
                            // utils.lazyload(imgs);
                    }
                } catch {}
            }
        })
        
        utils.t({
            url: e + "ogame",
            type: "post",
            data: JSON.stringify({
                "channel":"thumb-game",
                "page":1,
                "type":"HOT GAMES"
            }),
            timeout: 5e3,
            error: function() {},
            success: function(e) {
                var  cardNode = $(".box1")
                cardNode.find(".title>a").attr("href",'/more2.html?type=HOT GAMES')
                try {
                    if (e) {
                        
                        var t, n, s, o, 
                             s = JSON.parse(e), 
                             t = s.data,
                            a = "",
                            r = "",
                            c = t.splice(0, 4),
                            l = t;
                            c.map(e => {
                                a += '<li class="items"><a href="info.html?id='+e.id+'"><img src="'+(e.gameBigImage||'./images/default.png')+'" alt=""/><p>'+e.gamename+'</p></a></li>'
                            }), $(".box-list1").html(a)
                            var imgs = $('.item');
                            utils.lazyload(imgs);
                  
                        
                    }
                } catch {}
            }
        })
        utils.readRecord()
        window.onscroll = function() {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight-100) {
                // 滚动到页面底部时触发
                if(loading)
                {
                    loading = false
                    loadMore();
                }
            }
        }
        function loadMore(){
            //gameList = utils.getLocalData("minigame_data")
            // utils.t({
            //     url: e + "ogame",
            //     type: "post",
            //     data: JSON.stringify({
            //         "channel":"bg-play",
            //         "page":currPage
            //     }),
            //     timeout: 5e3,
            //     error: function() {},
            //     success: function(e) {
            //         try {
            //             if (e) {
            //                 var t, n, s, o, 
            //                 s = JSON.parse(e), t = s.data
            //                     a = "",
            //                     l = t;
            //                     var me = $(".box-game3")
            //                     t.map((e,idx)=> {
            //                         a+='<div class="item"><a href="info.html?id='+e.id+'"></a><img src="'+e.gamelogo+'" alt="" /><div class="intro"><p class="title">'+e.gamename+'</p><p><span>5.0</span><img src="'+require("../images/xx.png")+'" alt="" /></p></div></div>'
            //                     })
            //                     me.append(a)
            //                     currPage++
            //                     loading = true
         
            //             }
            //         } catch {}
            //     }
            // })
        }

        document.addEventListener('gesturestart', function (event) {
            event.preventDefault()
        })
    });
})
    
    
